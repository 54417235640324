import React, { createContext, useContext, useState, useEffect } from 'react';
import { useServiceLogger } from './ServiceLoggerContext';
import { jsonRpcRequest } from '../components/api';
import { useAuth } from './AuthContext'; // Import AuthContext to get the user

const ConnectionContext = createContext();

export const ConnectionProvider = ({ children }) => {
  const { sendConsolidatedLogs, serviceLogs } = useServiceLogger();
  const { user } = useAuth(); // Get the user from AuthContext
  const [isConnected, setIsConnected] = useState(false);
  const [selectedPort, setSelectedPort] = useState(null);
  const [serialNumber, setSerialNumberState] = useState(null);
  const [logs, setLogs] = useState([]);
  const [availablePorts, setAvailablePorts] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState({
    firmware_version: null,
    device_name: null,
    _id: null,
    manufacture_date: null
  });
  const [parameters, setParameters] = useState({});
  const [features, setFeatures] = useState({});
  const [components, setComponents] = useState({});
  const [needDetailsFetch, setNeedDetailsFetch] = useState(false);

  useEffect(() => {
    console.log('Connection state changed:', { isConnected, selectedPort, serialNumber, deviceDetails, parameters, features, components });
  }, [isConnected, selectedPort, serialNumber, deviceDetails, parameters, features, components]);

  useEffect(() => {
    if (needDetailsFetch) {
      fetchDeviceDetails();
      setNeedDetailsFetch(false);
    }
  }, [needDetailsFetch]);

  const fetchDeviceDetails = async () => {
    if (serialNumber) {
      try {
        const data = await jsonRpcRequest({
          jsonrpc: '2.0',
          method: 'get',
          params: { target: 'DeviceDetails' },
          id: new Date().getTime()
        });
        console.log('Fetched device details:', data);

        setDeviceDetails(prevDetails => ({
          ...prevDetails,
          firmware_version: data.result.version,
          parameters: data.result.parameters,
          features: data.result.features,
          manufacture_date: data.result.manufacture_date // Include manufacture date
        }));
        setComponents(data.result.components || {});
      } catch (error) {
        console.error('Failed to fetch device details:', error);
      }
    }
  };

  const setSerialNumber = (serial) => {
    setSerialNumberState(serial);
    localStorage.setItem('serialNumber', serial);
  };

  const connectPort = async (path) => {
    console.log(`Connecting to port: ${path}`);
    if (!isConnected && selectedPort === null) {
      try {
        const data = await jsonRpcRequest({
          jsonrpc: '2.0',
          method: 'connectPort',
          params: { path, userId: user.id }, // Pass userId here
          id: new Date().getTime()
        });
        console.log(`Connection data: ${JSON.stringify(data)}`);
  
        if (data.serialNumber) {
          setSerialNumber(data.serialNumber);
  
          const device = await jsonRpcRequest({
            jsonrpc: '2.0',
            method: 'getDevice',
            params: { serialNumber: data.serialNumber },
            id: new Date().getTime()
          });
  
          setDeviceDetails({
            firmware_version: data.version,
            device_name: device.device_name,
            _id: device._id,
            manufacture_date: device.manufacture_date, // Store manufacture date
            parameters: data.parameters,
            features: data.features,
          });
  
          setComponents(data.components || {});
          setIsConnected(true);
          setSelectedPort(path);
          alert('Device is now connected');
        } else {
          alert('Failed to connect to the device');
        }
      } catch (error) {
        console.error('Failed to connect to the device:', error);
        alert('Failed to connect to the device');
      }
    }
  };

  const disconnectPort = async () => {
    console.log('Disconnecting port');
    try {
      const serialNumber = localStorage.getItem('serialNumber');
      if (serialNumber) {
        await sendConsolidatedLogs(serialNumber);
      }

      await jsonRpcRequest({
        jsonrpc: '2.0',
        method: 'disconnectPort',
        params: {},
        id: new Date().getTime()
      });
      setIsConnected(false);
      setSelectedPort(null);
      setSerialNumber(null);
      setLogs([]);
      setDeviceDetails({
        firmware_version: null,
        device_name: null,
        _id: null,
        manufacture_date: null, // Reset manufacture date
        parameters: {},
        features: {}
      });
      setComponents({});
      alert('Device is now disconnected');
    } catch (error) {
      console.error('Failed to disconnect from the device:', error);
      alert('Failed to disconnect from the device');
    }
  };

  const fetchAvailablePorts = async () => {
    try {
      const ports = await jsonRpcRequest({
        jsonrpc: '2.0',
        method: 'listAvailablePorts',
        params: {},
        id: new Date().getTime()
      });
      console.log(`Available ports: ${JSON.stringify(ports)}`);
      setAvailablePorts(ports);
    } catch (error) {
      console.error('Failed to fetch serial ports:', error);
    }
  };

  useEffect(() => {
    fetchAvailablePorts();
  }, []);

  useEffect(() => {
    if (components.length > 0) {
      console.log('Components updated:', components);
    }
  }, [components]);

  const sendCommand = async (method, params) => {
    try {
      console.log(`Sending command ${method} with params:`, params); // Log the command being sent
      const response = await jsonRpcRequest({
        jsonrpc: '2.0',
        method,
        params,
        id: new Date().getTime()
      });
      return response;
    } catch (error) {
      console.error(`Failed to send command ${method}:`, error);
      throw error;
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isConnected) {
        const confirmationMessage = 'You are connected to a device. Please disconnect before leaving the page.';
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isConnected]);

  return (
    <ConnectionContext.Provider value={{
      isConnected,
      connectPort,
      disconnectPort,
      setIsConnected,
      setSelectedPort,
      selectedPort,
      serialNumber,
      setSerialNumber,
      logs,
      setLogs,
      deviceDetails,
      setDeviceDetails,
      parameters,
      setParameters,
      features,
      setFeatures,
      components,
      setComponents,
      needDetailsFetch,
      setNeedDetailsFetch,
      fetchDeviceDetails,
      sendCommand
    }}>
      {children}
    </ConnectionContext.Provider>
  );
};

export const useConnection = () => useContext(ConnectionContext);
