import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLaptop } from 'react-icons/fa';
import './ViewDevice.css'; // Assuming similar styling
import { BASE_URL } from '../../constants/constants';  // Adjust the path as necessary

function AddDevice() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    device_name: '',
    serial_number: '',
    manufacture_date: new Date().toISOString().slice(0, 16),
    alarm_mute: false,
    alternating_cycle: '10 Minutes',
    comfort_setting: 'Default',
    cooling_feature: true,
    mode: '1 in 2',
    last_service: new Date().toISOString().slice(0, 16),
    firmware_version: '1.0.0',
  });

  const handleBackClick = () => {
    navigate('/devices');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/api/devices`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Device added successfully');
        navigate('/devices');
      } else {
        alert('Failed to add device');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while adding the device');
    }
  };

  return (
    <div className="view-user-page">
        <div className="content">
          <div className="view-user-header">
            <div className="content-title">
              <FaLaptop className="content-icon" />
              <h2>Add Device</h2>
            </div>
            <button className="add-user-button" onClick={handleBackClick}>Back</button>
          </div>
          <form className="view-user-content" onSubmit={handleSubmit}>
            <div className="user-details">
              <div className="user-info">
                <div className="info-row">
                  <div className="info-item">
                    <label>Device Name</label>
                    <input
                      type="text"
                      name="device_name"
                      value={formData.device_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="info-item">
                    <label>Serial Number</label>
                    <input
                      type="text"
                      name="serial_number"
                      value={formData.serial_number}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="info-row">
                  <div className="info-item">
                    <label>Manufacture Date</label>
                    <input
                      type="datetime-local"
                      name="manufacture_date"
                      value={formData.manufacture_date}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="info-item">
                    <label>Alarm Mute</label>
                    <select
                      name="alarm_mute"
                      value={formData.alarm_mute}
                      onChange={handleChange}
                      required
                    >
                      <option value={true}>Muted</option>
                      <option value={false}>Audible</option>
                    </select>
                  </div>
                </div>
                <div className="info-row">
                  <div className="info-item">
                    <label>Alternating Cycle Time</label>
                    <select
                      name="alternating_cycle"
                      value={formData.alternating_cycle}
                      onChange={handleChange}
                      required
                    >
                      <option value="5 Minutes">5 Minutes</option>
                      <option value="10 Minutes">10 Minutes</option>
                      <option value="15 Minutes">15 Minutes</option>
                      <option value="20 Minutes">20 Minutes</option>
                      <option value="25 Minutes">25 Minutes</option>
                      <option value="30 Minutes">30 Minutes</option>
                      <option value="60 Minutes">60 Minutes</option>
                    </select>
                  </div>
                  <div className="info-item">
                    <label>Comfort Pressure Setting</label>
                    <select
                      name="comfort_setting"
                      value={formData.comfort_setting}
                      onChange={handleChange}
                      required
                    >
                      <option value="Increase 3">Increase 3</option>
                      <option value="Increase 2">Increase 2</option>
                      <option value="Increase 1">Increase 1</option>
                      <option value="Default">Default</option>
                      <option value="Decrease 1">Decrease 1</option>
                      <option value="Decrease 2">Decrease 2</option>
                      <option value="Decrease 3">Decrease 3</option>
                    </select>
                  </div>
                </div>
                <div className="info-row">
                  <div className="info-item">
                    <label>Cooling Feature</label>
                    <select
                      name="cooling_feature"
                      value={formData.cooling_feature}
                      onChange={handleChange}
                      required
                    >
                      <option value="On">On</option>
                      <option value="Off">Off</option>
                    </select>
                  </div>
                  <div className="info-item">
                    <label>Mode</label>
                    <select
                      name="mode"
                      value={formData.mode}
                      onChange={handleChange}
                      required
                    >
                      <option value="1 in 2">1 in 2</option>
                      <option value="1 in 4">1 in 4</option>
                    </select>
                  </div>
                </div>
                <div className="info-row">
    
                  <div className="info-item">
                    <label>Firmware Version</label>
                    <input
                      type="text"
                      name="firmware_version"
                      value={formData.firmware_version}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className="save-button">Save</button>
          </form>
        </div>
      </div>
  );
}

export default AddDevice;
