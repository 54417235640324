import React, { useState, useEffect } from 'react';
import { FaLock, FaUnlock, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import moment from 'moment';
import './ConnectedDevice.css';
import { useAuth } from '../../context/AuthContext';
import { useConnection } from '../../context/ConnectionContext';
import { jsonRpcRequest } from '../../components/api';
import { BASE_URL } from '../../constants/constants';  // Adjust the path as necessary

function ConnectedDevice() {
  const [isLocked, setIsLocked] = useState(true);
  const [availablePorts, setAvailablePorts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [lastAccessLog, setLastAccessLog] = useState(null);
  const [lastServiceLog, setLastServiceLog] = useState(null);
  const { isConnected, selectedPort, serialNumber, setSelectedPort, deviceDetails, connectPort, disconnectPort, logs, setLogs, fetchDeviceDetails } = useConnection();
  const { user } = useAuth();
  const [updatedDetails, setUpdatedDetails] = useState({
    alarm_mute: false,
    alternating_cycle: '',
    comfort_setting: '',
    cooling_feature: false,
    mode: ''
  });
  const [databaseDetails, setDatabaseDetails] = useState(null);
  const [manufactureDate, setManufactureDate] = useState('');
  const [deviceConfigChanged, setDeviceConfigChanged] = useState(false);
  const [differences, setDifferences] = useState([]);

  useEffect(() => {
    fetchAvailablePorts();
  }, []);

  useEffect(() => {
    if (isConnected && serialNumber) {
      fetchLogs(serialNumber);
    } else {
      setLogs([]);
    }
  }, [isConnected, serialNumber]);

  useEffect(() => {
    if (serialNumber) {
      fetchLastAccessLog(serialNumber);
      fetchLastServiceLog(serialNumber);
      fetchDatabaseDetails(serialNumber);
    }
  }, [serialNumber]);

  useEffect(() => {
    if (deviceDetails && deviceDetails.parameters && deviceDetails.features) {
      const newDetails = {
        alarm_mute: deviceDetails.parameters.alarm_mute,
        alternating_cycle: deviceDetails.parameters.alternating_cycle?.toString() || '',
        comfort_setting: deviceDetails.parameters.comfort_setting?.toString() || '',
        cooling_feature: deviceDetails.features.cooling_feature,
        mode: deviceDetails.features.mode || ''
      };
      setUpdatedDetails(newDetails);
      if (databaseDetails && JSON.stringify(newDetails) !== JSON.stringify(databaseDetails)) {
        setDeviceConfigChanged(true);
        setDifferences(getDetailedDifferences(newDetails, databaseDetails));
      } else {
        setDeviceConfigChanged(false);
        setDifferences([]);
      }
    }
  }, [deviceDetails, databaseDetails]);

  const fetchAvailablePorts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/jsonrpc/ports`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const ports = await response.json();
      setAvailablePorts(ports);
    } catch (error) {
      console.error('Failed to fetch serial ports:', error);
    }
  };

  const fetchLogs = async (serialNumber) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/logs/device/serial/${serialNumber}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch logs');
      }
      const logs = await response.json();
      console.log('Fetched logs:', logs); // Debugging log
      setLogs(logs);
    } catch (error) {
      console.error('Failed to fetch logs:', error);
    }
  };

  const fetchLastAccessLog = async (serialNumber) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/accessLogs/last/${serialNumber}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch last access log');
      }
      const log = await response.json();
      setLastAccessLog(log);
    } catch (error) {
      console.error('Failed to fetch last access log:', error);
    }
  };

  const fetchLastServiceLog = async (serialNumber) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/serviceHistories/last-service/${serialNumber}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch last service log');
      }
      const log = await response.json();
      setLastServiceLog(log);
    } catch (error) {
      console.error('Failed to fetch last service log:', error);
    }
  };

  const fetchDatabaseDetails = async (serialNumber) => {
    try {
      console.log(`Fetching database details for serial number: ${serialNumber}`);
      const token = localStorage.getItem('token');
      const url = `${BASE_URL}/api/devices/serial/${serialNumber}`;
      console.log(`Request URL: ${url}`);

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text(); // Capture error message from server
        console.error(`Failed to fetch database details: ${errorText}`);
        throw new Error(`Failed to fetch database details: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Database details:', data); // Debugging log
      const dbDetails = {
        alarm_mute: data.alarm_mute,
        alternating_cycle: data.alternating_cycle?.toString() || '',
        comfort_setting: data.comfort_setting?.toString() || '',
        cooling_feature: data.cooling_feature,
        mode: data.mode || ''
      };
      setDatabaseDetails(dbDetails);
      setManufactureDate(data.manufacture_date); // Store manufacture date separately
    } catch (error) {
      console.error('Failed to fetch database details:', error);
    }
  };

  const getDetailedDifferences = (deviceDetails, databaseDetails) => {
    const differences = [];
    for (const key in deviceDetails) {
      if (deviceDetails[key] !== databaseDetails[key]) {
        differences.push({
          key,
          deviceValue: deviceDetails[key],
          databaseValue: databaseDetails[key]
        });
      }
    }
    return differences;
  };

  const fetchLogDetails = async (logId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/logs/${logId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const log = await response.json();
      console.log('Fetched log details:', log); // Debugging log
      setSelectedLog(log);
    } catch (error) {
      console.error('Error fetching log details:', error);
    }
  };

  const formatDateTime = (dateTime) => {
    return moment(dateTime).format('DD/MM/YYYY HH:mm:ss');
  };

  const toggleLock = () => {
    setIsLocked(!isLocked);
  };

  const handleSave = async (details = updatedDetails) => {
    if (!serialNumber) {
      console.error('Serial number is missing');
      return;
    }

    const allowedFields = [
      'alarm_mute',
      'alternating_cycle',
      'comfort_setting',
      'cooling_feature',
      'mode'
    ];

    const updates = {};
    allowedFields.forEach(field => {
      if (details[field] !== undefined) {
        updates[field] = details[field] !== '' ? details[field] : null; // Handle empty string as null
      }
    });

    console.log('Selected options:', updates); // Log selected options

    try {
      const commandToSend = {
        jsonrpc: '2.0',
        method: 'set',
        params: {
          target: 'DeviceDetails',
          serialNumber, // Include serialNumber in the params
          ...updates
        },
        id: new Date().getTime()
      };

      console.log('Command to be sent:', JSON.stringify(commandToSend, null, 2)); // Log command

      const response = await jsonRpcRequest(commandToSend);

      console.log('Response received:', JSON.stringify(response, null, 2)); // Log response

      if (response.result) {
        alert('Device parameters updated successfully');
        setIsLocked(true);
        setDeviceConfigChanged(false);
        setDifferences([]);
        // Fetch the latest device details after updating the parameters
        fetchDeviceDetails();
      } else if (response.error) {
        alert(`Failed to update device parameters: ${response.error.message}`);
      } else {
        alert('Unknown error occurred while updating device parameters');
      }
    } catch (error) {
      console.error('Error updating device parameters:', error);
      alert('An error occurred while updating the device parameters');
    }
  };

  const handleApplyChanges = async () => {
    try {
      // Update the device configuration with the details retrieved from the database
      await handleSave(databaseDetails);
      setDeviceConfigChanged(false);
      setDifferences([]);
    } catch (error) {
      console.error('Error applying changes:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedDetails({ ...updatedDetails, [name]: value });
  };

  const toggleConnection = async () => {
    if (isConnected) {
      await disconnectPort();
    } else if (selectedPort) {
      await connectPort(selectedPort);
    } else {
      fetchAvailablePorts();
      setIsModalVisible(true);
    }
  };

  const handlePortSelection = async (path) => {
    setSelectedPort(path);
    await connectPort(path);
    setIsModalVisible(false);
  };

  const handleLogClick = (logId) => {
    fetchLogDetails(logId);
  };

  const handleCloseModal = () => {
    setSelectedLog(null);
  };

  return (
    <div className="connected-device-page">
      <div className="content">
        <div className="grid-container">
          <div className="status-box">
            <h2>
              Connection Status
              {isConnected ? (
                <FaCheckCircle className="connected-status-icon green-icon" />
              ) : (
                <FaTimesCircle className="connected-status-icon red-icon" />
              )}
            </h2>
            <button className="connectionbutton" onClick={toggleConnection}>
              {isConnected ? 'Disconnect' : 'Connect'}
            </button>
          </div>
          <div className="device-name">
            <h2>{isConnected ? deviceDetails.device_name : '-'}</h2>
            <div className="device-details">
              <div className="device-detail">
                <span>Serial Number</span>
                <span className="value">{isConnected ? serialNumber : '-'}</span>
              </div>
              <div className="device-detail">
                <span>Firmware Version</span>
                <span className="value">{isConnected ? deviceDetails.firmware_version : '-'}</span>
              </div>
              <div className="device-detail">
                <span>Last Service Date</span>
                <span className="value">{lastServiceLog ? new Date(lastServiceLog.service_date).toLocaleDateString() : '-'}</span>
              </div>

            </div>
          </div>
          <div className="details">
            <h3>Details</h3>
            <div className="details-info">
              <div className="detail-item">
                <span className="detail-label">Last Serviced By:</span>
                <input type="text" value={lastServiceLog ? `${lastServiceLog.technician_id?.firstName} ${lastServiceLog.technician_id?.lastName}` : '-'} className="detail-value" readOnly />
              </div>
              <div className="detail-item">
                <span className="detail-label">Last Accessed By:</span>
                <input type="text" value={lastAccessLog ? `${lastAccessLog.user_id?.firstName} ${lastAccessLog.user_id?.lastName}` : lastAccessLog?.first_name || '-'} className="detail-value" readOnly />
              </div>
              <div className="detail-item">
                <span className="detail-label">Last Accessed Date:</span>
                <input type="text" value={lastAccessLog ? formatDateTime(lastAccessLog.access_time) : '-'} className="detail-value" readOnly />
              </div>
              <div className="detail-item">
                <span className="detail-label">Manufacture Date</span>
                <input type="text" value={manufactureDate ? new Date(manufactureDate).toLocaleDateString() : '-'} className="detail-value" readOnly />
              </div>
            </div>
          </div>
          <div className="parameters-and-logs">
            <div className="parameters">
              <h3>
                Parameters {isLocked ? <FaLock className="lock-icon" onClick={toggleLock} /> : <FaUnlock className="lock-icon unlocked" onClick={toggleLock} />}
              </h3>
              <div className="parameters-info">
                <div className="parameter-item">
                  <span className="parameter-label">Alarm Mute Setting:</span>
                  <select
                    className={`parameter-select ${isLocked ? '' : 'unlocked'}`}
                    disabled={isLocked || !isConnected}
                    name="alarm_mute"
                    value={updatedDetails.alarm_mute}
                    onChange={handleInputChange}
                  >
                    {isConnected ? (
                      <>
                        <option value={false}>Muted</option>
                        <option value={true}>Audible</option>
                      </>
                    ) : (
                      <option>-</option>
                    )}
                  </select>
                </div>
                <div className="parameter-item">
                  <span className="parameter-label">Alternating Cycle Time:</span>
                  <select
                    className={`parameter-select ${isLocked ? '' : 'unlocked'}`}
                    disabled={isLocked || !isConnected}
                    name="alternating_cycle"
                    value={updatedDetails.alternating_cycle}
                    onChange={handleInputChange}
                  >
                    {isConnected ? (
                      <>
                        <option value="5">5 Minutes</option>
                        <option value="10">10 Minutes</option>
                        <option value="15">15 Minutes</option>
                        <option value="20">20 Minutes</option>
                        <option value="25">25 Minutes</option>
                        <option value="30">30 Minutes</option>
                        <option value="60">60 Minutes</option>
                      </>
                    ) : (
                      <option>-</option>
                    )}
                  </select>
                </div>
                <div className="parameter-item">
                  <span className="parameter-label">Comfort Pressure Setting:</span>
                  <select
                    className={`parameter-select ${isLocked ? '' : 'unlocked'}`}
                    disabled={isLocked || !isConnected}
                    name="comfort_setting"
                    value={updatedDetails.comfort_setting}
                    onChange={handleInputChange}
                  >
                    {isConnected ? (
                      <>
                        <option value="3">Increase 3</option>
                        <option value="2">Increase 2</option>
                        <option value="1">Increase 1</option>
                        <option value="0">Default</option>
                        <option value="-1">Decrease 1</option>
                        <option value="-2">Decrease 2</option>
                        <option value="-3">Decrease 3</option>
                      </>
                    ) : (
                      <option>-</option>
                    )}
                  </select>
                </div>
              </div>
              <h3>Features</h3>
              <div className="features-info">
                {user?.role === 'super-admin' && (
                  <div className="feature-item">
                    <span className="feature-label">Cooling Feature:</span>
                    <select
                      className={`feature-select ${isLocked ? '' : 'unlocked'}`}
                      disabled={isLocked || !isConnected}
                      name="cooling_feature"
                      value={updatedDetails.cooling_feature}
                      onChange={handleInputChange}
                    >
                      {isConnected ? (
                        <>
                          <option value="true">On</option>
                          <option value="false">Off</option>
                        </>
                      ) : (
                        <option>-</option>
                      )}
                    </select>
                  </div>
                )}
                <div className="feature-item">
                  <span className="feature-label">Mode:</span>
                  <select
                    className={`feature-select ${isLocked ? '' : 'unlocked'}`}
                    disabled={isLocked || !isConnected}
                    name="mode"
                    value={updatedDetails.mode}
                    onChange={handleInputChange}
                  >
                    {isConnected ? (
                      <>
                        <option value="1in2">1 in 2</option>
                        <option value="1in4">1 in 4</option>
                      </>
                    ) : (
                      <option>-</option>
                    )}
                  </select>
                </div>
              </div>
              {!isLocked && (
                <div className="save-button-container">
                  <button className="save-button" onClick={() => handleSave()}>Save</button>
                </div>
              )}
              {deviceConfigChanged && !isLocked && (
                <div className="apply-changes-container">
                  <div className="separator"></div>
                  <p className="notice">A different configuration has been found in the database, would you like to save and apply these changes?</p>
                  <div className="differences">
                    <h4>Differences:</h4>
                    <ul>
                      {differences.map((diff, index) => (
                        <li key={index}>
                          <strong>{diff.key}</strong>: Device Value = {diff.deviceValue.toString()}, Database Value = {diff.databaseValue.toString()}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <button className="apply-changes-button" onClick={handleApplyChanges}>Apply Changes</button>
                </div>
              )}
            </div>
            {(isConnected && logs.length > 0 && (user && (user.role === 'super-admin' || user.role === 'service-technician'))) && (
              <div className="logs">
                <h3>Logs</h3>
                <table className="logs-table">
                  <thead>
                    <tr>
                      <th>Details</th>
                      <th>User</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.map((log) => (
                      <tr key={log._id} onClick={() => handleLogClick(log._id)}>
                        <td>{log.details}</td>
                        <td>{log.first_name ? log.first_name : log.user_id ? `${log.user_id.firstName} ${log.user_id.lastName}` : '-'}</td>
                        <td>{formatDateTime(log.access_time)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      {isModalVisible && (
        <div className="modal-overlay">
          <div className="port-modal">
            <h4>Select a Port</h4>
            <ul className="port-list">
              {availablePorts.map((port, index) => (
                <li key={index} onClick={() => handlePortSelection(port.path)}>
                  {port.friendlyName} ({port.path})
                </li>
              ))}
            </ul>
            <button onClick={() => setIsModalVisible(false)}>Close</button>
          </div>
        </div>
      )}

      {selectedLog && (
        <>
          <div className="modal-overlay" onClick={handleCloseModal}></div>
          <div className="modal">
            <h2>Log Details</h2>
            <p><strong>Name:</strong> {selectedLog.first_name ? selectedLog.first_name : selectedLog.user_id ? `${selectedLog.user_id.firstName} ${selectedLog.user_id.lastName}` : '-'}</p>
            <p><strong>Comment:</strong> {selectedLog.details}</p>
            <p><strong>Date:</strong> {formatDateTime(selectedLog.access_time)}</p>
            <textarea value={selectedLog.log_content} readOnly className="log-content-textarea" />
            <button onClick={handleCloseModal}>Close</button>
          </div>
        </>
      )}
    </div>
  );
}

export default ConnectedDevice;
