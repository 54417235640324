import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaUsers, FaLock, FaUnlock, FaTrash } from 'react-icons/fa';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization
import './ViewAddUser.css';
import { BASE_URL } from '../../constants/constants';  // Adjust the path as necessary

function ViewUser() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [isLocked, setIsLocked] = useState(true);
  const [editedUser, setEditedUser] = useState({});
  const roleOptions = [
    { value: 'super-admin', label: 'Super Admin' },
    { value: 'service-technician', label: 'Service Technician' },
    { value: 'clinician', label: 'Clinician' },
  ];

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
        const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to fetch user data');
        }
  
        const data = await response.json();
        setUser(data);
        setEditedUser(data);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
  
    fetchUser();
  }, [userId]);

  const toggleLock = () => {
    setIsLocked(!isLocked);
  };

  const handleSave = async () => {
    // Phone number validation
    const phone = editedUser.phone;
    const phoneErrors = [];
    if (!phone || phone.length < 10) {
      phoneErrors.push('Phone number must be valid');
    }

    if (phoneErrors.length > 0) {
      alert(phoneErrors.join('\n'));
      return;
    }

    try {
      const token = localStorage.getItem('token'); // Get the token from localStorage
      const response = await fetch(`${BASE_URL}/api/users/${user._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the Authorization header
        },
        body: JSON.stringify(editedUser),
      });

      if (response.ok) {
        alert('User updated successfully');
        setIsLocked(true);
      } else {
        const errorData = await response.json();
        alert(`Failed to update user: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error updating user:', error);
      alert('An error occurred while updating the user');
    }
  };

  const handleBackClick = () => {
    navigate('/users');
  };

  const handleDeleteUser = async () => {
    const confirmed = window.confirm('Are you sure you want to delete this user?');
    if (!confirmed) return;

    try {
      const token = localStorage.getItem('token'); // Get the token from localStorage
      const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`, // Include the Authorization header
        },
      });

      if (response.ok) {
        alert('User deleted successfully');
        navigate('/users');
      } else {
        const errorData = await response.json();
        alert(`Failed to delete user: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('An error occurred while deleting the user');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUser({ ...editedUser, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setEditedUser({ ...editedUser, phone: value });
  };

  const formatDateTime = (dateTime) => {
    return moment(dateTime).format('DD/MM/YYYY HH:mm:ss');
  };

  const handleDeviceClick = (deviceId) => {
    navigate(`/view-device/${deviceId}`);
  };

  // Validate image URL
  const validateImageUrl = (url) => {
    // Check for valid URL format
    try {
      new URL(url);
    } catch (_) {
      return false;
    }
    // Allow only specific domains (example)
    const trustedDomains = ['app.elata.au'];
    const domain = new URL(url).hostname;
    return trustedDomains.includes(domain);
  };
  

  return (
    <div className="view-user-page">
      <div className="content">
        <div className="view-user-header">
          <div className="content-title">
            <FaUsers className="content-icon" />
            <h2>View User</h2>
          </div>
          <div className="button-group">
            <button className="add-user-button" onClick={handleBackClick}>Back</button>
            {!isLocked && (
              <button className="delete-user-button" onClick={handleDeleteUser}>Delete</button>
            )}
          </div>
        </div>
        <div className="view-user-content">
          <div className="lock-container">
            {isLocked ? (
              <FaLock className="lock-icon" onClick={toggleLock} />
            ) : (
              <FaUnlock className="lock-icon unlocked" onClick={toggleLock} />
            )}
          </div>
          <div className="user-details">
          <div className="user-image">
              <img
                src={
                  user.profilePic && validateImageUrl(`https://app.elata.au${user.profilePic}`)
                    ? `https://app.elata.au${DOMPurify.sanitize(user.profilePic)}`
                    : '/images/users/default.png'
                }
                alt={`${DOMPurify.sanitize(user.firstName)} ${DOMPurify.sanitize(user.lastName)}`}
              />
            </div>
            <div className="user-info">
              <div className="info-row">
                <div className="info-item">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={editedUser.firstName || ''}
                    onChange={handleChange}
                    readOnly={isLocked}
                  />
                </div>
                <div className="info-item">
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={editedUser.lastName || ''}
                    onChange={handleChange}
                    readOnly={isLocked}
                  />
                </div>
              </div>
              <div className="info-row">
                <div className="info-item">
                  <label>Email Address</label>
                  <input
                    type="text"
                    name="email"
                    value={editedUser.email || ''}
                    onChange={handleChange}
                    readOnly={isLocked}
                  />
                </div>
                <div className="info-item">
                  <label>Phone Number</label>
                  <PhoneInput
                    country={'au'}
                    value={editedUser.phone || ''}
                    onChange={handlePhoneChange}
                    disabled={isLocked}
                    required
                  />
                </div>
              </div>
              <div className="info-row">
                <div className="info-item">
                  <label>Role</label>
                  <select
                    name="role"
                    value={editedUser.role || ''}
                    onChange={handleChange}
                    disabled={isLocked}
                  >
                    {roleOptions.map(option => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {!isLocked && (
            <div className="save-button-container">
              <button className="save-button" onClick={handleSave}>Save</button>
            </div>
          )}
          <div className="device-list">
            <h3>Devices</h3>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Serial Number</th>
                  <th>Last Login</th>
                </tr>
              </thead>
              <tbody>
                {user.devices?.map((device, index) => (
                  <tr key={index} className="device-row" onClick={() => handleDeviceClick(device._id)}>
                    <td>{device.device_name}</td>
                    <td>{device.serial_number}</td>
                    <td>{formatDateTime(device.lastLogin)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewUser;
