import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const RedirectToRoleBasedHome = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      if (user.role === 'super-admin') {
        navigate('/users');
      } else if (user.role === 'service-technician') {
        navigate('/devices');
      }
    }
  }, [user, navigate]);

  return null;
};

export default RedirectToRoleBasedHome;
