import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './LoginPage.css';
import { BASE_URL } from '../constants/constants';  // Adjust the path as necessary

function LoginPage() {
  const navigate = useNavigate();
  const { login, isAuthenticated, user } = useAuth();
  const formRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isAuthenticated()) {
      redirectToRoleBasedRoute(user.role);
    }
  }, [isAuthenticated, user, navigate]);

  const redirectToRoleBasedRoute = (role) => {
    switch (role) {
      case 'super-admin':
        navigate('/users');
        break;
      case 'service-technician':
        navigate('/devices');
        break;
      case 'clinician':
        navigate('/connected-device');
        break;
      default:
        navigate('/');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleLoginClick();
    }
  };

  useEffect(() => {
    const form = formRef.current;
    if (form) {
      form.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (form) {
        form.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);

  const handleLoginClick = async () => {
  const email = document.getElementById('email').value;
  const password = document.getElementById('password').value;

  try {
    const response = await fetch(`${BASE_URL}/api/auth/login`, { // Use relative URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    const data = await response.json();
    if (response.ok) {
      if (data.shouldEnable2FA) {
        navigate('/setup-2fa', { state: { user: data.user, token: data.token } });
      } else if (data.needs2FA) {
        navigate('/verify-2fa', { state: { user: data.user, token: data.token } });
      } else {
        login({ user: data.user, token: data.token });
        redirectToRoleBasedRoute(data.user.role);
      }
    } else {
      setErrorMessage(data.message);
    }
  } catch (error) {
    setErrorMessage('Login failed due to server error.');
  }
};

  return (
    <div className="login-page">
      <img src="/Enable.png" alt="Logo" className="logo" />
      <h1>Log in</h1>
      <p>Enter your details to continue</p>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <form ref={formRef}>
        <div className="form-group">
          <label htmlFor="email">Enter your email</label>
          <input type="email" id="email" placeholder="email@email.com" />
        </div>
        <div className="form-group">
          <label htmlFor="password">Enter your password</label>
          <input type="password" id="password" placeholder="********" />
        </div>
        <div className="form-actions">
          <button type="button" className="login-button" onClick={handleLoginClick}>Log in</button>
          <a href="#" className="recover-password">Recover password</a>
        </div>
      </form>
      <div className="divider"><span>or</span></div>
      <button className="upload-log-button" onClick={() => navigate('/upload-log')}>Upload Log</button>
    </div>
  );
}

export default LoginPage;
