import React, { useState, useEffect } from 'react';
import ComponentStatus from './ComponentStatus';
import ResetView from './ServiceCounterReset';
import Logs from './Logs';
import CompressorTest from './Compressor';
import AirFilterTest from './AirFilter';
import SolenoidTest from './Solenoid';
import SensorTest from './PressureSensors';
import FirmwareUpdate from './FirmwareUpdate';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './Service.css';
import { useConnection } from '../../context/ConnectionContext';
import { useServiceLogger } from '../../context/ServiceLoggerContext';
import { useAuth } from '../../context/AuthContext';
import { BASE_URL } from '../../constants/constants';  // Adjust the path as necessary

function Service() {
  const [isLocked, setIsLocked] = useState(true);
  const [selectedComponent, setSelectedComponent] = useState('Component Status');
  const [resetMode, setResetMode] = useState(false);
  const [availablePorts, setAvailablePorts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lastServiceLog, setLastServiceLog] = useState(null);
  const [activeButton, setActiveButton] = useState('Component Status');
  const [deviceName, setDeviceName] = useState('');
  const { isConnected, selectedPort, setSelectedPort, serialNumber, deviceDetails, connectPort, disconnectPort, sendCommand, needDetailsFetch, setNeedDetailsFetch } = useConnection();
  const { serviceLogs, clearLogs, logServiceActivity, sendConsolidatedLogs } = useServiceLogger();
  const { user } = useAuth();

  useEffect(() => {
    fetchAvailablePorts();
  }, []);

  useEffect(() => {
    if (isConnected && serialNumber) {
      fetchLastServiceLog(serialNumber);
      fetchDeviceDetailsBySerial(serialNumber);
    }
  }, [isConnected, serialNumber]);

  const fetchAvailablePorts = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/jsonrpc/ports`);
      const ports = await response.json();
      console.log('Available ports:', ports);
      setAvailablePorts(ports);
    } catch (error) {
      console.error('Failed to fetch serial ports:', error);
    }
  };

  const fetchLastServiceLog = async (serialNumber) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/serviceHistories/last-service/${serialNumber}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch last service log');
      }
      const log = await response.json();
      setLastServiceLog(log);
    } catch (error) {
      console.error('Failed to fetch last service log:', error);
    }
  };

  const fetchDeviceDetailsBySerial = async (serialNumber) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/devices/serial/${serialNumber}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch device details');
      }
      const device = await response.json();
      setDeviceName(device.device_name);
    } catch (error) {
      console.error('Failed to fetch device details by serial number:', error);
    }
  };

  const handleComponentClick = (component) => {
    console.log('Component clicked:', component);
    if (component.startsWith('Solenoid')) {
      setSelectedComponent('Solenoid');
      setActiveButton('Solenoid');
    } else if (component.startsWith('Pressure')) {
      setSelectedComponent('Pressure Sensor');
      setActiveButton('Pressure Sensor');
    } else if (component.startsWith('Air Filter')) {
      setSelectedComponent('Air Filter');
      setActiveButton('Air Filter');
    } else if (component.startsWith('Compressor')) {
      setSelectedComponent('Compressor');
      setActiveButton('Compressor');
    } else if (component.startsWith('Firmware')) {
      setSelectedComponent('Firmware Update');
      setActiveButton('Firmware Update');
    } else {
      setSelectedComponent(component);
      setActiveButton(component);
    }
    setResetMode(false);
  };

  const handleResetClick = () => {
    console.log('Reset button clicked');
    setSelectedComponent('Component Status');
    setResetMode(true);
    setActiveButton('Service Counter Reset');
  };

  const toggleConnection = async () => {
    if (isConnected) {
      console.log('Disconnecting port');
      await disconnectPort();
    } else if (selectedPort) {
      console.log('Connecting to port:', selectedPort);
      await connectPort(selectedPort);
    } else {
      console.log('Fetching available ports');
      fetchAvailablePorts();
      setIsModalVisible(true);
    }
  };

  const handlePortSelection = async (path) => {
    console.log('Port selected:', path);
    setSelectedPort(path);
    await connectPort(path);
    setIsModalVisible(false);
  };

  const renderComponentStatus = () => {
    if (resetMode) {
      return <ResetView />;
    }

    switch (selectedComponent) {
      case 'Component Status':
        return <ComponentStatus onComponentClick={handleComponentClick} />;
      case 'Firmware Update':
        return <FirmwareUpdate />;
      case 'Compressor':
        return <CompressorTest />;
      case 'Solenoid':
        return <SolenoidTest />;
      case 'Pressure Sensor':
        return <SensorTest />;
      case 'Air Filter':
        return <AirFilterTest />;
      default:
        return (
          <div>
            <h3>Select a component to view its status</h3>
          </div>
        );
    }
  };

  const renderLogsSection = () => {
    return <Logs />;
  };

  return (
    <div className="service-page">
      <div className="content">
        <div className="grid-container">
          <div className="status-box">
            <h2>
              Connection Status
              {isConnected ? (
                <FaCheckCircle className="connected-status-icon green-icon" />
              ) : (
                <FaTimesCircle className="connected-status-icon red-icon" />
              )}
            </h2>
            <button className="connectionbutton" onClick={toggleConnection}>
              {isConnected ? 'Disconnect' : 'Connect'}
            </button>
          </div>
          <div className="device-name">
            <h2>{isConnected ? deviceName : '-'}</h2>
            <div className="device-details">
              <div className="device-detail">
                <span>Serial Number</span>
                <span className="value">{isConnected ? serialNumber : '-'}</span>
              </div>
              <div className="device-detail">
                <span>Firmware Version</span>
                <span className="value">{isConnected ? deviceDetails.firmware_version : '-'}</span>
              </div>
              <div className="device-detail">
                <span>Last Service Date</span>
                <span className="value">{lastServiceLog ? new Date(lastServiceLog.service_date).toLocaleDateString() : '-'}</span>
              </div>
            </div>
          </div>
          <div>
            <div className="details">
              <div className="details-info">
                <button
                  className={`detail-button ${activeButton === 'Component Status' ? 'active' : ''}`}
                  onClick={() => handleComponentClick('Component Status')}
                >
                  Component Status
                </button>
                <button
                  className={`detail-button ${activeButton === 'Air Filter' ? 'active' : ''}`}
                  onClick={() => handleComponentClick('Air Filter')}
                >
                  Air Filter
                </button>
                <button
                  className={`detail-button ${activeButton === 'Compressor' ? 'active' : ''}`}
                  onClick={() => handleComponentClick('Compressor')}
                >
                  Compressor
                </button>
                <button
                  className={`detail-button ${activeButton === 'Pressure Sensor' ? 'active' : ''}`}
                  onClick={() => handleComponentClick('Pressure Sensor')}
                >
                  Pressure Sensor
                </button>
                <button
                  className={`detail-button ${activeButton === 'Solenoid' ? 'active' : ''}`}
                  onClick={() => handleComponentClick('Solenoid')}
                >
                  Solenoid
                </button>
                <button
                  className={`detail-button ${activeButton === 'Service Counter Reset' ? 'active' : ''}`}
                  onClick={handleResetClick}
                >
                  Service Counter Reset
                </button>
                <button
                  className={`detail-button ${activeButton === 'Firmware Update' ? 'active' : ''}`}
                  onClick={() => handleComponentClick('Firmware Update')}
                >
                  Firmware Update
                </button>
              </div>
            </div>
            <div className="logs-section">
              {renderLogsSection()}
            </div>
          </div>

          {renderComponentStatus()}
        </div>
      </div>

      {isModalVisible && (
        <div className="modal-overlay">
          <div className="port-modal">
            <h4>Select a Port</h4>
            <ul className="port-list">
              {availablePorts.map((port, index) => (
                <li key={index} onClick={() => handlePortSelection(port.path)}>
                  {port.friendlyName} ({port.path})
                </li>
              ))}
            </ul>
            <button onClick={() => setIsModalVisible(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Service;
