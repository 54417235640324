import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUsers } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './ViewAddUser.css';
import { BASE_URL } from '../../constants/constants';  // Adjust the path as necessary

function AddUser() {
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
    if (!/\d/.test(password)) {
      errors.push("Password must include at least one digit.");
    }
    if (!/[a-z]/i.test(password)) {
      errors.push("Password must include at least one letter.");
    }
    if (!/[A-Z]/.test(password)) {
      errors.push("Password must include at least one uppercase letter.");
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push("Password must include at least one special character.");
    }
    return errors;
  };

  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: 'super-admin',
    password: '',
  });

  const handleBackClick = () => {
    navigate('/users');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the password first
    const passwordErrors = validatePassword(formData.password);
    if (passwordErrors.length > 0) {
      alert('Password does not meet requirements:\n' + passwordErrors.join('\n'));
      return;
    }

    try {
      const token = localStorage.getItem('token'); // Get the token from localStorage
      const response = await fetch(`${BASE_URL}/api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the Authorization header
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('User added successfully');
        navigate('/users');
      } else {
        const data = await response.json();
        alert('Failed to add user: ' + (data.message || ''));
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while adding the user');
    }
  };

  return (
    <div className="view-user-page">
      <div className="content">
        <div className="view-user-header">
          <div className="content-title">
            <FaUsers className="content-icon" />
            <h2>Add User</h2>
          </div>
          <button className="add-user-button" onClick={handleBackClick}>Back</button>
        </div>
        <form className="view-user-content" onSubmit={handleSubmit}>
          <div className="user-details">
            <div className="user-image">
              <img src='/images/users/default.png' alt="Default" />
            </div>
            <div className="user-info">
              <div className="info-row">
                <div className="info-item">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="info-item">
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="info-row">
                <div className="info-item">
                  <label>Email Address</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="info-item">
                  <label>Phone Number</label>
                  <PhoneInput
                    country={'au'}
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    required
                  />
                </div>
              </div>
              <div className="info-row">
                <div className="info-item">
                  <label>Role</label>
                  <select
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    required
                  >
                    <option value="super-admin">Super Admin</option>
                    <option value="service-technician">Service Technician</option>
                    <option value="clinician">Clinician</option>
                  </select>
                </div>
                <div className="info-item">
                  <label>Password</label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <button type="submit" className="save-button">Save</button>
        </form>
      </div>
    </div>
  );
}

export default AddUser;
