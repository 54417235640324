import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConnection } from '../context/ConnectionContext'; // Import ConnectionContext
import './UploadLogFile.css';
import { BASE_URL } from '../constants/constants';  // Adjust the path as necessary

function UploadLogFile() {
  const navigate = useNavigate();
  const [details, setDetails] = useState('');
  const [name, setName] = useState('');
  const { isConnected, connectPort, disconnectPort } = useConnection(); // Use ConnectionContext

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('details', details);
    formData.append('name', name);

    try {
      const response = await fetch(`${BASE_URL}/api/logs/upload`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to upload log file');
      }

      const data = await response.json();
      alert('Log file uploaded successfully');
      setDetails('');
      setName('');
    } catch (error) {
      console.error('Error uploading log:', error);
      alert(`An error occurred: ${error.message}`);
    }
  };

  const handleReturn = () => {
    navigate('/');
  };

  const requestPort = async () => {
    try {
      const port = await navigator.serial.requestPort();
      await connectPort(port);
    } catch (error) {
      if (error.name === 'NotFoundError') {
        console.log('No port selected by the user');
      } else {
        console.error('Failed to get serial ports:', error);
      }
    }
  };

  const toggleConnection = async () => {
    if (isConnected) {
      await disconnectPort();
    } else {
      await requestPort();
    }
  };

  return (
    <div className="upload-log-page">
      <div className="upload-log-file">
        <img src="/Enable.png" alt="Logo" className="logo" />
        <h1>Upload Log File</h1>
        <p>Connect to the device and upload the log file.</p>
        <div className="connection-status">
          <button
            className={`logconnectionbutton ${isConnected ? 'connected' : 'disconnected'}`}
            onClick={toggleConnection}
          >
            {isConnected ? 'Disconnect Device' : 'Connect Device'}
          </button>
          <div className="status-text">
            Connection Status:<br />
            <span className={isConnected ? 'connected' : 'disconnected'}>
              {isConnected ? 'Connected' : 'Disconnected'}
            </span>
          </div>
        </div>
        {isConnected && (
          <>
            <div className="form-group">
              <label htmlFor="details">Comments</label>
              <textarea
                id="details"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Your Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <button
              className="upload-button"
              onClick={handleUpload}
              disabled={!details || !name} // Enable button only if details and name are filled
            >
              Upload Log File
            </button>
          </>
        )}
        <div className="divider"><span>or</span></div>
        <button className="return-button" onClick={handleReturn}>Return to Login</button>
      </div>
    </div>
  );
}

export default UploadLogFile;
