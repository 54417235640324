import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../constants/constants"; // Adjust the path as necessary

const SerialDeviceCommunicator = () => {
  const [port, setPort] = useState(null);
  const [output, setOutput] = useState("");
  const [isConnected, setIsConnected] = useState(false);

  // Predefined command names to request from the backend
  const commandNames = [
    "getDeviceDetails",
    "startDevice",
    "stopDevice",
    "resetDevice",
  ];

  // Connect to the serial port
  const connectSerial = async () => {
    try {
      console.log("Attempting to connect to serial port...");
      const selectedPort = await navigator.serial.requestPort();
      await selectedPort.open({ baudRate: 9600 });
      setPort(selectedPort);
      setIsConnected(true);
      alert("Connected to serial port");
      console.log("Connected to serial port:", selectedPort);
      readSerialData(selectedPort);
    } catch (error) {
      console.error("Error connecting to serial port:", error);
    }
  };

  // Disconnect from the serial port
  const disconnectSerial = async () => {
    if (port) {
      console.log("Disconnecting from serial port...");
      await port.close();
      setPort(null);
      setIsConnected(false);
      alert("Disconnected from serial port");
      console.log("Disconnected from serial port.");
    }
  };

  // Request a command from the backend and send it to the serial device
  const requestAndSendCommand = async (commandName) => {
    try {
      console.log(`Requesting command: ${commandName} from backend...`);
      // Request the command from the backend
      const response = await axios.get(
        `${BASE_URL}/api/rpcjson/${commandName}`
      );
      const command = response.data;
      console.log("Command received from backend:", command);

      // Send the command to the serial device
      await sendJsonRpcCommand(command);
    } catch (error) {
      console.error("Error fetching or sending command:", error);
      setOutput(
        (prevOutput) => prevOutput + "\nError: " + error.message
      );
    }
  };

  // Send a JSON-RPC command to the serial device
  const sendJsonRpcCommand = async (command) => {
    if (port) {
      try {
        console.log("Sending JSON-RPC command to serial device:", command);
        const encoder = new TextEncoderStream();
        const writableStreamClosed = encoder.readable.pipeTo(port.writable);
        const writer = encoder.writable.getWriter();
        await writer.write(JSON.stringify(command) + "\n");
        writer.close();
        await writableStreamClosed;
      } catch (error) {
        console.error("Error sending JSON-RPC command:", error);
      }
    } else {
      alert("Please connect to a serial port first.");
    }
  };

  // Read data from the serial port
  const readSerialData = async (port) => {
    const decoder = new TextDecoderStream();
    const readableStreamClosed = port.readable.pipeTo(decoder.writable);
    const reader = decoder.readable.getReader();

    try {
      let rawData = ""; // Buffer to accumulate data
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          reader.releaseLock();
          break;
        }

        rawData += value; // Accumulate the data
        console.log("Raw data received:", rawData);

        // Try parsing JSON data
        try {
          const parsedData = JSON.parse(rawData);
          // Format the output with pretty print
          console.log("Parsed JSON data:", parsedData);
          setOutput(
            (prevOutput) =>
              prevOutput + "\n" + JSON.stringify(parsedData, null, 2)
          );
          rawData = ""; // Reset the buffer after successful parse
        } catch (err) {
          // If parse fails, continue accumulating data
          console.error("Error parsing JSON:", err);
          console.log(
            "Raw data likely incomplete or malformed, continuing accumulation..."
          );
        }
      }
    } catch (error) {
      console.error("Error reading from serial port:", error);
    } finally {
      reader.releaseLock();
    }
  };

  return (
    <div>
      <h1>Serial Port JSON-RPC Communicator</h1>
      {!isConnected ? (
        <button onClick={connectSerial}>Connect to Serial Port</button>
      ) : (
        <button onClick={disconnectSerial}>Disconnect Serial Port</button>
      )}
      <div>
        {commandNames.map((commandName, index) => (
          <button
            key={index}
            onClick={() => requestAndSendCommand(commandName)}
          >
            {commandName}
          </button>
        ))}
      </div>
      <div>
        <h3>Output:</h3>
        <pre>{output}</pre>
      </div>
    </div>
  );
};

export default SerialDeviceCommunicator;
