import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FaUsers, FaLaptop, FaPlug, FaWrench, FaSignOutAlt, FaCog } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';
import './Layout.css';

const Sidebar = React.memo(() => {
  const location = useLocation();
  const { user, logout } = useAuth(); // Get user from useAuth
  const navigate = useNavigate();
  
  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const isUsersActive = location.pathname.startsWith('/users') || location.pathname.startsWith('/add-user') || location.pathname.match(/^\/view-user\/[a-zA-Z0-9]+$/);
  const isDevicesActive = location.pathname.startsWith('/devices') || location.pathname.startsWith('/add-device') || location.pathname.match(/^\/view-device\/[a-zA-Z0-9]+$/);

  return (
    <div className="sidebar">
      <div className="logosidebar">
        <img src="/Enable.png" alt="Logo" className="logo-image" />
      </div>
      <div className="sidebar-menu-bar">
        <div className="sidebar-menu-items">
          {user && user.role === 'super-admin' && (
            <NavLink exact to="/users" className={`sidebar-link ${isUsersActive ? 'active' : ''}`}>
              <FaUsers className="sidebar-icon" />
              <span>Users</span>
            </NavLink>
          )}
          {(user && (user.role === 'super-admin' || user.role === 'service-technician')) && (
          <NavLink exact to="/devices" className={`sidebar-link ${isDevicesActive ? 'active' : ''}`}>
            <FaLaptop className="sidebar-icon" />
            <span>Devices</span>
          </NavLink>
          )}
          <NavLink exact to="/connected-device" className="sidebar-link">
            <FaPlug className="sidebar-icon" />
            <span>Connected Device</span>
          </NavLink>
          {(user && (user.role === 'super-admin' || user.role === 'service-technician')) && (
          <NavLink exact to="/service" className="sidebar-link">
            <FaWrench className="sidebar-icon" />
            <span>Service</span>
          </NavLink>
          )}
        </div>
      </div>
      <div class="sidebar-bottom">
        <NavLink exact to="/settings" className="sidebar-link">
              <FaCog className="sidebar-icon" />
              <span>Settings</span>
            </NavLink>
        <div className="sidebar-logout" onClick={handleLogout}>
          <FaSignOutAlt className="sidebar-icon" />
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
});

export default Sidebar;
