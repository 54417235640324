import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useConnection } from '../../context/ConnectionContext';
import { BASE_URL } from '../../constants/constants';  // Adjust the path as necessary

const Logs = () => {
  const [remarks, setRemarks] = useState('');
  const { user } = useAuth();
  const { serialNumber, deviceDetails } = useConnection();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const logData = {
      device_id: deviceDetails._id,
      serialNumber,
      file_name: 'placeholder.txt', // Placeholder file name
      upload_date: new Date(),
      details: remarks,
      first_name: user.firstName,
    };

    try {
      const response = await fetch(`${BASE_URL}/api/logs/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(logData),
      });

      if (response.ok) {
        alert('Log created successfully');
        setRemarks(''); // Clear the textarea
      } else {
        const errorData = await response.json();
        alert(`Failed to create log: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error creating log:', error);
      alert('An error occurred while creating the log');
    }
  };

  return (
    <div className="logs">
      <h3>Log a Fault</h3>
      <form onSubmit={handleSubmit}>
        <label htmlFor="remarks">Remarks</label>
        <textarea 
          id="remarks" 
          name="remarks" 
          rows="5"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        ></textarea>
        <div className="submit-button-container">
          <button type="submit" className="submit-button">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default Logs;
