import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ children, roles }) => {
  const { user, isAuthenticated } = useAuth();

  console.log('PrivateRoute user:', user);
  console.log('PrivateRoute roles:', roles);

  if (!isAuthenticated()) {
    return <Navigate to="/" />;
  }

  if (roles && !roles.includes(user.role)) {
    console.log('User role not authorized:', user.role);
    return <Navigate to="/unauthorized" />; // Create an Unauthorized component or route
  }

  return children;
};

export default PrivateRoute;
