import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLaptop, FaSearch } from 'react-icons/fa';
import moment from 'moment';
import './Devices.css';
import { useAuth } from '../../context/AuthContext'; // Import useAuth
import { BASE_URL } from '../../constants/constants';  // Adjust the path as necessary

function Devices() {
  const [devices, setDevices] = useState([]);
  const navigate = useNavigate();
  const { user } = useAuth(); // Get user from useAuth

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/devices`);
        const data = await response.json();
        setDevices(data);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();
  }, []);

  const handleRowClick = (device) => {
    navigate(`/view-device/${device._id}`);
  };

  const handleAddClick = () => {
    console.log('Navigating to /add-device'); // Logging to verify click
    navigate('/add-device');
  };

  const formatDateTime = (dateTime) => {
    return moment(dateTime).format('DD/MM/YYYY HH:mm:ss');
  };

  return (
    <div className="devices-page">
      <div className="content">
          <div className="content-header">
            <div className="content-title">
              <FaLaptop className="content-icon" />
              <h2>Devices</h2>
            </div>
            {user && user.role === 'super-admin' && (
              <button className="add-device-button" onClick={handleAddClick}>Add Device</button>
            )}
          </div>
          <div className="search-sort">
            <div className="search-bar">
              <FaSearch className="search-icon" />
              <input type="text" placeholder="Search" className="search-input" />
            </div>
            <div className="sort-dropdown">
              <label htmlFor="sort-options" className="sort-label">Sort by:</label>
              <select id="sort-options" className="sort-select">
                <option value="Oldest">Oldest</option>
                <option value="Newest">Newest</option>
                <option value="Name">Name</option>
                <option value="Firmware Version">Firmware Version</option>
                <option value="Last Serviced">Last Serviced</option>
              </select>
            </div>
          </div>
          <div className="table-section">
            <table className="devices-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Serial Number</th>
                  <th>Firmware Version</th>
                  <th>Last Serviced</th>
                </tr>
              </thead>
              <tbody>
                {devices.map((device, index) => (
                  <tr key={index} onClick={() => handleRowClick(device)}>
                    <td>{device.device_name}</td>
                    <td>{device.serial_number}</td>
                    <td>{device.firmware_version}</td>
                    <td>{formatDateTime(device.last_service)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
    </div>
  );
}

export default Devices;
