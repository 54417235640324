import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useServiceLogger } from './ServiceLoggerContext';
import { BASE_URL } from '../constants/constants';  // Adjust the path as necessary

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { sendConsolidatedLogs } = useServiceLogger();

  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const login = (userData) => {
    console.log('Logging in:', userData);
    localStorage.setItem('user', JSON.stringify(userData.user));
    localStorage.setItem('token', userData.token);
    const decodedToken = jwtDecode(userData.token);
    console.log('Decoded token:', decodedToken);
    setUser({ ...userData.user, id: decodedToken.userId, role: decodedToken.role });
  };

  const logout = async () => {
    console.log('Logging out...');
    const serialNumber = localStorage.getItem('serialNumber');
    if (serialNumber) {
      console.log('Sending consolidated logs before logging out...');
      await sendConsolidatedLogs(serialNumber);
    }
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('serialNumber');
    setUser(null);
  };

  const isAuthenticated = () => !!user;

  const updateUser = async (updatedData, file) => {
    const formData = new FormData();
    Object.keys(updatedData).forEach(key => {
      formData.append(key, updatedData[key]);
    });
    if (file) {
      formData.append('profilePicture', file);
    }

    try {
      const token = localStorage.getItem('token');
      console.log('Token from localStorage:', token);
      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch(`${BASE_URL}/api/auth/update`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error data:', errorData);
        throw new Error('Failed to update profile');
      }

      const data = await response.json();
      setUser(data.user);
      localStorage.setItem('user', JSON.stringify(data.user));
    } catch (error) {
      console.error('Error updating profile:', error.message);
      throw error;
    }
  };

  useEffect(() => {
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      const parsedUser = JSON.parse(savedUser);
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        console.log('Decoded token on load:', decodedToken);
        setUser({ ...parsedUser, id: decodedToken.userId, role: decodedToken.role });
      } else {
        setUser(parsedUser);
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
