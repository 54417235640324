import React from 'react';

const ResetView = () => {
  return (
    <div className="parameters-and-logs">
        <div className="component-status">
    <div>
      
      <h3>Component Counts</h3>
      <div className="component-status-container vertical-line">

        




        Not sure what's going here yet
       {/* <div className="component-status-left">




         <div className="component-item">
            <span>Compressor</span>
            <span className="component-value">546</span>
          </div>
          <div className="component-item">
            <span>Filter</span>
            <span className="component-value">775</span>
          </div>
          <div className="component-item">
            <span>Solenoid 1</span>
            <span className="component-value">3456</span>
          </div>
          <div className="component-item">
            <span>Solenoid 2</span>
            <span className="component-value">657</span>
          </div>
          <div className="component-item">
            <span>Solenoid 3</span>
            <span className="component-value">45</span>
          </div>
          <div className="component-item">
            <span>Solenoid 4</span>
            <span className="component-value">2345</span>
          </div>
        </div>
        <div className="component-status-right">
          <div className="component-item">
            <span>Sensor 1</span>
            <span className="component-value">43</span>
          </div>
          <div className="component-item">
            <span>Sensor 2</span>
            <span className="component-value">657</span>
          </div>
          <div className="component-item">
            <span>Sensor 3</span>
            <span className="component-value">356</span>
          </div>
          <div className="component-item">
            <span>Sensor 4</span>
            <span className="component-value">843</span>
          </div>
          <div className="component-item">
            <span>Battery</span>
            <span className="component-value">4573</span>
          </div>
        </div>*/}




      </div>
    </div>
    </div>
    </div>
  );
};

export default ResetView;
