import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import './Layout.css';

function Header() {
  const { user } = useAuth();

  let profilePicUrl;
  if (user?.profilePic) {
    profilePicUrl = user.profilePic.includes('/images/default.png') ? '/images/users/default.png' : `https://app.elata.au${user.profilePic}`;
  } else {
    profilePicUrl = '/images/users/default.png';
  }

  return (
    <header className="header">
      <div className="welcome">
        {user ? (
          <Link to="/settings" className="profile-link">
            <img src={profilePicUrl} alt="Profile" className="profile-picture" />
            Hi! <strong>{user.firstName}</strong>
          </Link>
        ) : (
          <span>
            Hi! <strong>Guest</strong>
          </span>
        )}
      </div>
    </header>
  );
}

export default Header;
