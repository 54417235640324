import { BASE_URL } from '../constants/constants';  // Adjust the path as necessary

export const fetchConfig = async () => {
  const response = await fetch(`${BASE_URL}/api/config/max-usage`);
  if (!response.ok) {
    throw new Error('Failed to fetch configuration');
  }
  const config = await response.json();
  return config;
};

export const jsonRpcRequest = async (jsonRpcCommand, includeUserId = false) => {
  try {
    const token = localStorage.getItem('token');
    if (includeUserId) {
      const userId = localStorage.getItem('userId');
      if (!userId) {
        throw new Error('User ID not found in local storage');
      }
      jsonRpcCommand.params = {
        ...jsonRpcCommand.params,
        userId
      };
    }

    const response = await fetch(`${BASE_URL}/api/jsonrpc`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(jsonRpcCommand)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    if (responseData.error) {
      throw new Error(responseData.error.message);
    }

    return responseData.result;
  } catch (error) {
    console.error(`Failed to send JSON-RPC request: ${error.message}`);
    throw error;
  }
};
