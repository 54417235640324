import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { BASE_URL } from '../constants/constants';  // Adjust the path as necessary

const ServiceLoggerContext = createContext();

export const ServiceLoggerProvider = ({ children }) => {
  const [serviceLogs, setServiceLogs] = useState([]);
  const serviceLogsRef = useRef(serviceLogs);

  useEffect(() => {
    serviceLogsRef.current = serviceLogs;
  }, [serviceLogs]);

  const logServiceActivity = (device_id, type_of_service, description, technician_id) => {
    if (!device_id || !technician_id) {
      console.log('Invalid service activity:', { device_id, type_of_service, description, technician_id });
      return;
    }

    const logEntry = {
      device_id,
      type_of_service,
      description,
      technician_id,
      service_date: new Date(),
    };

    console.log('Logging service activity:', logEntry);
    setServiceLogs((prevLogs) => [...prevLogs, logEntry]);
  };

  const sendConsolidatedLogs = async (serialNumber, useBeacon = false) => {
    console.log('sendConsolidatedLogs called with serialNumber:', serialNumber, 'and useBeacon:', useBeacon);
    if (!serviceLogsRef.current || serviceLogsRef.current.length === 0) {
      console.log('No service logs to send');
      return; // Do not attempt to send logs if there are none
    }

    const token = localStorage.getItem('token');
    const description = serviceLogsRef.current.map(log => log.description).join('\n');

    const payload = {
      serialNumber,
      logs: [{
        device_id: serviceLogsRef.current[0]?.device_id,
        type_of_service: 'Consolidated Service Log',
        description,
        technician_id: serviceLogsRef.current[0]?.technician_id,
        service_date: new Date(),
      }],
    };

    console.log('Payload to be sent:', JSON.stringify(payload));

    try {
      if (useBeacon) {
        const url = `${BASE_URL}/api/serviceHistories/service-history`;
        const blob = new Blob([JSON.stringify(payload)], { type: 'application/json' });
        const result = navigator.sendBeacon(url, blob);
        console.log('Service logs sent via sendBeacon, result:', result);
      } else {
        const response = await fetch(`${BASE_URL}/api/serviceHistories/service-history`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error('Failed to save service logs');
        }

        console.log('Service logs saved successfully');
        setServiceLogs([]); // Clear logs after successful submission
      }
    } catch (error) {
      console.error('Error saving service logs:', error);
      throw error;
    }
  };

  return (
    <ServiceLoggerContext.Provider value={{ logServiceActivity, sendConsolidatedLogs, serviceLogs }}>
      {children}
    </ServiceLoggerContext.Provider>
  );
};

export const useServiceLogger = () => useContext(ServiceLoggerContext);
