import React, { useState } from 'react';
import { useServiceLogger } from '../../context/ServiceLoggerContext';
import { useAuth } from '../../context/AuthContext';
import { useConnection } from '../../context/ConnectionContext';

const FirmwareUpdate = () => {
  const [showModal, setShowModal] = useState(false);
  const [outputText, setOutputText] = useState('');

  const { logServiceActivity } = useServiceLogger();
  const { user } = useAuth();
  const { isConnected, deviceDetails } = useConnection();

  const handleUpdateClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmUpdate = async () => {
    setShowModal(false);

    console.log('Device details:', deviceDetails);
    console.log('User details:', user);

    if (!deviceDetails._id || !user.id) {
      console.log('Missing device details or user information');
      return;
    }

    try {
      setOutputText('Firmware update is in progress...\n');
      await new Promise((resolve) => setTimeout(resolve, 5000));

      const randomOutput = 'Firmware update completed successfully.\n';
      setOutputText((prevText) => prevText + randomOutput);
      logServiceActivity(deviceDetails._id, 'Firmware Update', 'Firmware update completed', user.id);
    } catch (error) {
      setOutputText((prevText) => prevText + `Error during firmware update: ${error.message}\n`);
    }
  };

  return (
    <div className="parameters-and-logs">
      <div className="component-status">
        <div className="firmware-update">
          <h3>Firmware Update</h3>
          <div className="changelog">
            <h4>Changelog</h4>
            <ul>
              <li>
                <strong>Version 1.2.0:</strong>
                <ul>
                  <li>Enhanced performance for data processing</li>
                  <li>Fixed critical bug causing unexpected shutdowns</li>
                  <li>Added new security features to improve device protection</li>
                  <li>Updated user interface for better accessibility</li>
                </ul>
              </li>
              <li>
                <strong>Version 1.1.0:</strong>
                <ul>
                  <li>Improved firmware update process for faster installation</li>
                  <li>Resolved issues with sensor calibration</li>
                  <li>Minor bug fixes and stability improvements</li>
                </ul>
              </li>
              <li>
                <strong>Version 1.0.0:</strong>
                <ul>
                  <li>Initial release with basic features and functionalities</li>
                  <li>Support for XYZ Pump model</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="output-box">
            <h4>Update Output</h4>
            <textarea
              id="output"
              name="output"
              rows="5"
              disabled
              placeholder="Firmware update logs will appear here..."
              value={outputText}
            ></textarea>
          </div>
          <div className="submit-button-container">
            <button
              type="button"
              className={`submit-button ${!isConnected ? 'disabled' : ''}`}
              onClick={handleUpdateClick}
              disabled={!isConnected}
            >
              Update Firmware
            </button>
          </div>
        </div>
      </div>

      {showModal && (
        <>
          <div className="modal-overlay" onClick={handleCloseModal}></div>
          <div className="service-modal">
            <h4>Firmware Update</h4>
            <p>Are you sure you would like to update your firmware?</p>
            <p>You will be unable to use the device for X period of time while the firmware is updating.</p>
            <div className="service-button-group">
              <button onClick={handleConfirmUpdate}>Yes</button>
              <button onClick={handleCloseModal}>No</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FirmwareUpdate;
